<template>
  <div class="container" :style="backgroundDiv">
    <div class="userInfo">
      <img :src="avatar" alt="" />
      <span>{{ nickname }}</span>
      <span class="mobile">{{ mobile }}</span>
    </div>
    <div class="friend">
      <div>
        <p>{{numberOfTransactionsToday}}</p>
        <span>今日有效订单</span>
      </div>
      <div>
        <p>{{numberOfNewUsersYesterday}}</p>
        <span>昨日新用户数</span>
      </div>
      <div>
        <p>{{numberOfNewUsersInThePreviousDay}}</p>
        <span>前日新用户数</span>
      </div>
      <div>
        <p>{{totalNewcomerIncome}}</p>
        <span>总新人收入</span>
      </div>
    </div>
    <div class="inviteInfo">
      <div>我的PID</div>
      <p>{{ jd_pid }}</p>
      <span
        class="copy"
        @click="copy"
        slot="“content”"
        :data-clipboard-text="jd_pid"
        >复制PID</span
      >
      
    </div>
    <div class="showToast" v-if="showToast">
      <div class="showToast_con">{{ showToast }}</div>
    </div>
    
    <div class="success_tip" v-if="success_tip" @click="closeTip">
      <img src="../../assets/register-tip.png" />
    </div>
    <div class="download_tip" v-if="download_tip">
      <div class="download_con">
      <div class="title">提示信息</div>
      <div class="content">
        iOS版本正在审核中，先去邀请好友加入吧
      </div>
      <div class="button">
        <a @click="hideTip">确定</a>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
// import 'lib-flexible'

import { resetRem } from "@/assets/rem.js";
export default {
  data() {
    return {
      backgroundDiv: {
        backgroundImage: "url(" + require("../../assets/bg-h5.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      nickname: "",
      download_tip: false,
      success_tip:false,
      avatar: "",
      invite_code: "",
      invite_link: "",
      showToast: "",
      mobile:"",
      authCount:"",
      count:"",
      url:'',
      jd_pid:"",
      numberOfTransactionsToday:0,
      numberOfNewUsersYesterday:0,
      totalNewcomerIncome:0,
      numberOfNewUsersInThePreviousDay:0,
      bonus_number:0,
      bonus_fee_count:0
    };
  },
  created() {
    resetRem();
  },
  mounted() {
    this.getUserInfo();
    // this.friendInfo();
    // this.userDirectOrderBonusCount()
    // this.appPack()
  },
  methods: {
    
    appPack() {
      var that = this;
      that.axios.get("/app/open/appPack/release").then((res) => {
        //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
        if (that.$code(res)) {
          that.url = res.data.data.url;
        }
      });
    },
    getUserInfo() {
      var that = this;
      var data = {
        token: window.localStorage.getItem("token")?window.localStorage.getItem("token"):this.$route.query.token,
      };
      that.axios.get("/app/jdreward20211106/me", { params: data }).then((res) => {
        that.confirmLoading = false;
        //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
        if (res.data.code == 200) {
          that.nickname = res.data.data.nickname;
          that.avatar = res.data.data.avatar;
          that.numberOfTransactionsToday= res.data.data.numberOfTransactionsToday;
          that.numberOfNewUsersYesterday= res.data.data.numberOfNewUsersYesterday;
          that.totalNewcomerIncome= res.data.data.totalNewcomerIncome;
          that.numberOfNewUsersInThePreviousDay= res.data.data.numberOfNewUsersInThePreviousDay;
          // that.invite_code = res.data.data.invite_code;
          // that.invite_link =
          //   "https://fanhong.58auv.com/register?inviteCode=" +
          //   res.data.data.invite_code;
          that.mobile= res.data.data.mobile;
          that.jd_pid= res.data.data.jd_pid;
        } else if (res.data.code == -1){
          that.showToast = "登录失效";
          setTimeout(function () {
            that.showToast = "";
            window.localStorage.setItem(
                "token",""
              );
              that.$router.push({
                path: "/h5/jd2021Login",
            });
          }, 2000);
        } else {
          that.showToast = res.data.msg;
          setTimeout(function () {
            that.showToast = "";
          }, 2000);
        }
      });
    },
    friendInfo(){
      var that = this;
      var data = {
        token: window.localStorage.getItem("token")?window.localStorage.getItem("token"):this.$route.query.token,
      };
      that.axios.get("/app/user/directFriendsCount", { params: data }).then((res) => {
        that.confirmLoading = false;
        //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
        if (res.data.code == 200) {
          that.authCount = res.data.data.authCount;
          that.count = res.data.data.count;
        } else if (res.data.code == -1){
          that.showToast = "登录失效";
          setTimeout(function () {
            that.showToast = "";
            window.localStorage.setItem(
                "token",""
              );
              that.$router.push({
                path: "/loginApp",
            });
          }, 2000);
        }else {
          that.showToast = res.data.msg;
          setTimeout(function () {
            that.showToast = "";
          }, 2000);
        }
      });
    },
    userDirectOrderBonusCount(){
        var that=this
        that.axios.get("/app/order/userDirectOrderBonusCount").then((res) => {
        if (res.data.code == 200) {
          that.bonus_fee_count=res.data.data.bonus_fee_count;
          that.bonus_number=res.data.data.bonus_number;
        } else if (res.data.code == -1){
          that.showToast = "登录失效";
          setTimeout(function () {
            that.showToast = "";
            window.localStorage.setItem(
                "token",""
              );
              that.$router.push({
                path: "/loginApp",
            });
          }, 2000);
        } else {
          that.showToast = res.data.msg;
          setTimeout(function () {
            that.showToast = "";
          }, 2000);
        }
      });
    
    },
    closeTip() {
      this.success_tip = false;
    },
    copy() {
      var that = this;
      var clipboard = new this.Clipboard(".copy");
      clipboard.on("success", () => {
        that.showToast = "复制成功";
        setTimeout(function () {
          that.showToast = "";
        }, 2000);
        clipboard.destroy();
      });
      // 浏览器不支持
      clipboard.on("error", () => {
        that.showToast = "不支持";
        setTimeout(function () {
          that.showToast = "";
        }, 2000);
        // 释放内存
        clipboard.destroy();
      });
    },
    hideTip(){
      this.download_tip=false
    },
    download(e) {
      var that = this;
      if (e == "iOS") {
        // that.download_tip = true;
        that.url="https://apps.apple.com/cn/app/%E7%BF%BB%E7%BA%A2%E9%A6%86-%E4%B8%80%E6%AC%BE%E9%9D%A0%E7%9F%AD%E8%A7%86%E9%A2%91%E8%B5%9A%E9%92%B1%E7%9A%84app/id1552911522"
      } else {
        that.url="https://a.app.qq.com/o/simple.jsp?pkgname=com.xingke.fanhongguan"
      }
      window.location.href=that.url
    }
  },
};
</script>
<style scoped>
.container {
  width: 10rem;
  margin: 0 auto;
  height: 100vh;
  background: url("../../assets/bg-h5.png") no-repeat 50% 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.userInfo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.64rem;
  margin-top: -2.2rem;
}
.userInfo img {
  width: 2.667rem;
  height: 2.667rem;
  border-radius: 50%;
  margin-bottom: 0.2667rem;
}
.userInfo span {
  font-family: PingFang-SC-Bold;
  font-size: 0.4267rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 0.5867rem;
}
.friend {
  width: 7.733rem;
  height: 4.8rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.32rem;
}
.friend > div {
  width: 3.866rem;
  color: #fff;
  font-family: PingFangSC-Regular;
  text-align: center;
}
.friend > div p {
  color: #fff;
  font-size: 0.64rem;
  line-height: 0.88rem;
  margin-bottom: 0;
}
.friend > div span {
  font-size: 0.32rem;
  color: rgba(255, 255, 255, 0.5);
  line-height: 0.4533rem;
}
.pullNew{
  width: 7.733rem;
  height: 2.8rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.16rem;
  margin-bottom: 0.32rem;
  padding:0.4rem 0.48rem;
}
.pullNew .title{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color:#fff;
  font-size: 0.3733rem;
  line-height:0.53333rem;
  margin-bottom:0.4rem 
}
.pullNew .title p{
  margin: 0;
   display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.pullNew .title img{
  width:0.53333rem
}
.pullNew .detail p{
  font-size:0.32rem;
  color:rgba(255,255,255,.5);
  margin: 0;
  width:100%;
  line-height: 0.4533rem;
}
.pullNew .detail p:first-child{
  margin-bottom: 0.16rem;
}
.pullNew .detail .detail_money{
  color: #c7a379;
  font-weight: 700;
}
.pullNew .detail .detail_num{
  color: #fff;
}
.inviteInfo {
  width: 7.733rem;
  /* height: 5.32rem; */
  padding:0.6rem 0;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.inviteInfo div {
  font-family: PingFang-SC-Heavy;
  font-size: 0.3733rem;
  line-height: 0.5333rem;
  color: #ffffff;
  margin-bottom: 0.32rem;
}
.inviteInfo p {
  font-family: SourceHanSansCN-Heavy;
font-size: 0.6rem;
line-height: 0.8rem;
color: #DDB383;
    width: 88%;
    margin: 0 auto 0.5267rem;
    word-wrap: break-word;
font-weight: 800;
text-align: center;
}
.inviteInfo span {
    width: 88%;
  height: 1.067rem;
  background-image: linear-gradient(-45deg, #ddb383 0%, #b17b55 100%);
  border-radius: 0.1067rem;
  text-align: center;
  line-height: 1.067rem;
  font-family: PingFangSC-Regular;
  font-size: 0.4267;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.userInfo .mobile{
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.3733rem;
  padding-top:0.1rem
}
.showToast {
  width: 10rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
}
.showToast_con {
  height: 1.333rem;
  line-height: 1.333rem;
  background: #4c4c4c;
  border-radius: 0.667rem;
  padding: 0 1.28rem;
  font-family: PingFang-SC-Regular;
  font-size: 0.3733rem;
  color: #ffffff;
  letter-spacing: 0;
}
.download_btn{
  width: 7.733rem;
  position: fixed;
  bottom:0.5rem;
  left:50%;
  margin-left:-3.8665rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.download_btn span {
  display: block;
  width: 3.8rem;
  height: 1.3333rem;
  line-height: 1.3333rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.1067rem;
  border: 0;
  font-family: PingFangSC-Regular;
  font-size: 0.4267rem;
  color: #ffffff;
      /* color: #ddb383; */
  letter-spacing: 0;
  text-align: center;
  margin: 0 auto;
  padding:0
}
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.ant-btn-primary.active {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: none;
}
button[ant-click-animating-without-extra-node]:after{
  border:0 none;
  opacity:0;
  animation:none ease 0 1 normal
}
.gotoLogin {
  display: block;
  width: 7.6267rem;
  height: 1.3333rem;
  line-height: 1.3333rem;
  background:rgba(221,179,131,.1);
  border-radius: 0.1067rem;
  border: 0;
  font-family: PingFangSC-Regular;
  font-size: 0.4267rem;
  letter-spacing: 0;
  text-align: center;
  margin: 0 auto;
  color: #ddb383;
  /* margin-top:-0.32rem */
}
.download_tip {
  position: fixed;
  top:0;
  left:0;
  width:100%;height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background:rgba(0,0,0,.6)
}
.download_con{
  width: 8.72rem;
  height: 4.5933rem;
  border-radius: 0.32rem;
  background: #fff;
  padding: 0.533rem 0.8rem 0.8rem;
}
.download_tip .title {
  font-family: PingFangSC-Regular;
  font-size: 0.5333rem;
  line-height: 0.7467rem;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 0.32rem;
}
.download_tip .content {
  font-family: PingFangSC-Regular;
  font-size: 0.3733rem;
  color: #666666;
  letter-spacing: 0;
  line-height: 0.68rem;
}
.download_tip .button{
  display: flex;
  justify-content:space-between;
  align-items: center;
  flex-direction: row;
  margin-top:0.48rem
}
.download_tip .button *{
  border: 1px solid #979797;
  border-radius: 0.107rem;
  width: 8.72rem;
  height:1.333rem;
  line-height: 1.333rem;
  text-align: center;
  font-size: 0.4267rem;color: #333333;
  box-sizing: border-box;
}
.download_tip .button a:last-child{
  color:#fff;
  background-image: linear-gradient(-45deg, #DDB383 0%, #B17B55 100%);
  border:0
}
.success_tip {
  position: fixed;
  top: 0;
  left: 0;
  width: 10rem;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.success_tip img {
  width: 10rem;
}
</style>